import { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LogRocket from "logrocket";

import App from "./app";
import AppContext from "./context";
import EventDetail from "./eventdetail";
import EventList from "./eventlist";
import NavBar from "./navbar";
import PDF from "./pdf";

function getLoggedIn(user: Record<string, any>) {
  return user.token && user.name !== undefined;
}

const AppRoutes = () => {
  const user = JSON.parse(window.localStorage.getItem("USER")!) || {};
  const [loggedIn, setLoggedIn] = useState(getLoggedIn(user)) || false;

  LogRocket.init("emb2uz/misfits");
  if (loggedIn) {
    LogRocket.identify(user.id, {
      name: user.name.first.concat(" ", user.name.last),
      email: user.email,
      phone: user.phone,
      firstname: user.name.first,
      lastname: user.name.last,
    });
  }

  const setUser = (user: Record<string, any>) => {
    window.localStorage.setItem("USER", JSON.stringify(user));
    setLoggedIn(getLoggedIn(user));
  };

  const privateComponent = (component: React.FC) =>
    PrivateComponent(component, loggedIn);
  const semiPrivateComponent = (component: React.FC) =>
    SemiPrivateComponent(component, loggedIn);

  return (
    <AppContext.Provider value={{ user, setUser }}>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/event" element={privateComponent(EventList)} />
        <Route path="/event/:id" element={semiPrivateComponent(EventDetail)} />
        <Route path="/event/:clubId/:clubName/:id" element={semiPrivateComponent(EventDetail)} />
        <Route path="/events/:id" element={semiPrivateComponent(EventDetail)} />
        <Route path="/events/:clubId/:clubName/:id" element={semiPrivateComponent(EventDetail)} />
        <Route path="/pdf" element={<PDF />} />
      </Routes>
    </AppContext.Provider>
  );
};

function PrivateComponent(Component: React.FC, loggedIn: boolean) {
  if (!loggedIn) {
    const location = useLocation();
    if (location.pathname !== "/") {
      window.localStorage.setItem("redirect", location.pathname);
    }
    return <Navigate to="/" replace />;
  } else {
    return (
      <>
        <NavBar />
        <Component />
      </>
    );
  }
}

function SemiPrivateComponent(Component: React.FC, loggedIn: boolean) {
  if (!loggedIn) {
    return (
      <>
        <Component />
      </>
    );
  } else {
    return (
      <>
        <NavBar />
        <Component />
      </>
    );
  }
}

export default AppRoutes;
