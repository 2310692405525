import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Post } from "./requests";

const PDF = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filename = searchParams.get('filename');
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(
    () =>
      Post(
        `/file?filename=${filename}`,
        {},
        (data: Record<string, any>) => {
          const pdfUrl = data.files[0]['s3_url'];
          setPdfUrl(pdfUrl);
        },
        () => { }
      ),
    []
  );

  return <div style={{ height: "100vh" }}
    dangerouslySetInnerHTML={
      { __html: `<iframe src=${pdfUrl} width="100%" height="100%" style="border:none;"></iframe>` }
    }
  />;
};

export default PDF;
