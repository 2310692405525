import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "./context";
import "./navbar.css";

const Navbar = () => {
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div className="nav">
      <img
        src={require("../misfits.png")}
        height="50px"
        width="75px"
        alt="logo"
      />
      <ul>
        <NavLink
          onClick={() => {
            window.localStorage.clear();
            setUser({});
            navigate("/");
          }}
        >
          Logout
        </NavLink>
      </ul>
    </div>
  );
};

const NavLink = (props: Record<string, any>) => {
  return (
    <li>
      <a onClick={props.onClick}>{props.children}</a>
    </li>
  );
};

export default Navbar;
