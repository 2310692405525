import { useContext, useEffect, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { BsBookmarkDash } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { MdPeopleOutline } from "react-icons/md";

import AppContext from "./context";
import Pay from "./pay";
import { getDatetimeObj, getEventTimings } from "./datetime";
import { Post } from "./requests";
import "./event.css";

const GuestList = (props: Record<string, any>) => {
  const { participants = [] } = props;
  return (
    <div className="event-guest-list">
      {participants.length > 0 && (
        <>
          <label>Guest list</label>
          <ol>
            {participants.map((user: Record<string, any>, index: number) => (
              <li key={index}>
                <div className="event-guest-list-item">
                  {user.name} {getPaymentStatus(user.payment_status)}
                </div>
              </li>
            ))}
          </ol>
        </>
      )}
    </div>
  );
};

const getPaymentStatus = (paymentStatus: number) => {
  switch (paymentStatus) {
    case 1:
      return <AiOutlineExclamationCircle style={{ color: "red" }} />;
    case 2:
      return <AiOutlineCheckCircle style={{ color: "lightgreen" }} />;
    default:
      return null;
  }
};

const redirectToLogin = (navigate: Function, location: Location) => {
  window.localStorage.setItem("redirect", location.pathname);
  navigate("/");
};

const RegisterButton = (props: Record<string, any>) => {
  const { eventId, userId, event, setEvent } = props;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <button
      className="event-detail-button register-btn"
      onClick={() => {
        if (!userId) {
          redirectToLogin(navigate, location);
          return;
        }

        Post(
          "/event/register",
          {
            event_id: eventId,
            user_id: userId,
          },
          (data: Record<string, any>) => {
            setEvent({
              ...event,
              participants: data.participants,
              user_details: data.user_details,
            });
          },
          () => {}
        );
      }}
    >
      Register
    </button>
  );
};

const DeregisterButton = (props: Record<string, any>) => {
  const { eventId, userId, event, setEvent } = props;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <button
      className="event-detail-button deregister-btn"
      onClick={() => {
        if (!userId) {
          redirectToLogin(navigate, location);
          return;
        }

        Post(
          "/event/deregister",
          {
            event_id: eventId,
            user_id: userId,
          },
          (data: Record<string, any>) => {
            setEvent({
              ...event,
              participants: data.participants,
              user_details: data.user_details,
            });
          },
          () => {}
        );
      }}
    >
      Deregister
    </button>
  );
};

const PayButton = (props: Record<string, any>) => {
  const { eventId, userId, event, setEvent, guestCount, setShowPaymentModal } =
    props;
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button
      className="event-detail-button pay-btn"
      onClick={() => {
        if (!userId) {
          redirectToLogin(navigate, location);
          return;
        }

        setIsLoading(true);
        Post(
          "/event/register",
          {
            event_id: eventId,
            user_id: userId,
            guest_count: guestCount,
          },
          (data: Record<string, any>) => {
            if (event.payment_type == 2) {
              Post(
                "/event/pay",
                {
                  event_id: eventId,
                  user_id: userId,
                },
                (paymentData: Record<string, any>) => {
                  setEvent({
                    ...event,
                    participants: data.participants,
                    user_details: {
                      ...data.user_details,
                      payment_id: paymentData.payment_id,
                    },
                  });
                  setShowPaymentModal(true);
                  setIsLoading(false);
                },
                () => {}
              );
            } else {
              setEvent({
                ...event,
                participants: data.participants,
                user_details: data.user_details,
              });
              setShowPaymentModal(true);
              setIsLoading(false);
            }
          },
          () => {}
        );
      }}
      disabled={isLoading}
    >
      Pay
    </button>
  );
};

const PayAgainButton = (props: Record<string, any>) => {
  const { setShowPaymentModal } = props;
  return (
    <button
      className="event-detail-button pay-btn"
      onClick={() => setShowPaymentModal(true)}
    >
      Pay
    </button>
  );
};

const NO_TICKETS_MSG = "Tickets sold out";

const FreeEventFooter = (props: Record<string, any>) => {
  const { eventId, userId, userStatus, event, setEvent } = props;
  switch (userStatus) {
    case undefined:
      return (
        <RegisterButton
          eventId={eventId}
          userId={userId}
          event={event}
          setEvent={setEvent}
        />
      );
    case 1:
    case 2:
      return (
        <DeregisterButton
          eventId={eventId}
          userId={userId}
          event={event}
          setEvent={setEvent}
        />
      );
    default:
      return null;
  }
};

const EventTicket = (props: Record<string, any>) => {
  const { ticketPrice } = props;
  return (
    <div className="event-ticket">
      <BiRupee />
      <label>{ticketPrice / 100}</label>
    </div>
  );
};

const EventGuestCount = (props: Record<string, any>) => {
  const { guestCount, setGuestCount } = props;
  return (
    <div className="event-guest-count">
      <a
        onClick={() => {
          if (guestCount > 0) setGuestCount(guestCount - 1);
        }}
      >
        -
      </a>
      <label>{guestCount + 1}</label>
      <a onClick={() => setGuestCount(guestCount + 1)}>+</a>
    </div>
  );
};

const PaidEventFooter = (props: Record<string, any>) => {
  const {
    eventId,
    userId,
    userStatus,
    paymentStatus,
    event,
    participantCount,
    setEvent,
    setShowPaymentModal,
    guestCount: oldGuestCount,
  } = props;
  const [guestCount, setGuestCount] = useState(oldGuestCount);

  switch (userStatus) {
    case undefined:
      if (participantCount >= event.max_people) {
        return NO_TICKETS_MSG;
      }
      return (
        <div className="event-footer-with-ticket">
          <EventGuestCount
            guestCount={guestCount}
            setGuestCount={setGuestCount}
          />
          <EventTicket ticketPrice={event.ticket_price * (guestCount + 1)} />
          <PayButton
            eventId={eventId}
            userId={userId}
            event={event}
            setEvent={setEvent}
            setShowPaymentModal={setShowPaymentModal}
            guestCount={guestCount}
          />
        </div>
      );
    case 1:
    case 2:
      if (paymentStatus !== 2) {
        return (
          <div className="event-footer-with-ticket">
            <EventTicket ticketPrice={event.ticket_price * (guestCount + 1)} />
            <PayAgainButton setShowPaymentModal={setShowPaymentModal} />
          </div>
        );
      } else {
        return (
          <div className="event-footer-with-ticket">
            <EventGuestCount guestCount={guestCount} />
            <EventTicket ticketPrice={event.ticket_price * (guestCount + 1)} />
          </div>
        );
      }
    default:
      return null;
  }
};

const EventFooter = (props: Record<string, any>) => {
  const {
    eventId,
    userId,
    userStatus,
    paymentStatus,
    event,
    participantCount,
    setEvent,
    setShowPaymentModal,
    guestCount,
  } = props;

  // event.payment_type is undefined for free events
  if (event.payment_type) {
    return (
      <PaidEventFooter
        eventId={eventId}
        userId={userId}
        userStatus={userStatus}
        paymentStatus={paymentStatus}
        event={event}
        participantCount={participantCount}
        setEvent={setEvent}
        setShowPaymentModal={setShowPaymentModal}
        guestCount={guestCount}
      />
    );
  } else {
    return (
      <FreeEventFooter
        eventId={eventId}
        userId={userId}
        userStatus={userStatus}
        event={event}
        setEvent={setEvent}
      />
    );
  }
};

const EventDetail = () => {
  const { user } = useContext(AppContext);
  const { id: eventId } = useParams();
  const [event, setEvent] = useState<Record<string, any>>({});
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(
    () =>
      Post(
        "/event/detail",
        { event_id: eventId, user_id: user.id },
        (data: Record<string, any>) => setEvent(data),
        () => {}
      ),
    []
  );

  const startTime = getDatetimeObj(event.start_time);
  const endTime = getDatetimeObj(event.end_time);
  const paymentId = event.user_details?.payment_id;
  const guestCount = event.user_details?.guest_count || 0;

  if (Object.keys(event).length == 0) {
    return (
      <div className="event event-detail" style={{ display: "flex" }}>
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="event event-detail">
      <div className="event-body">
        <div className="event-header">
          <ul>
            <li>About</li>
          </ul>
        </div>
        <div className="event-name">{event.name}</div>
        <div className="event-body-item event-description">
          <BsBookmarkDash />
          <a>{event.description} </a>
        </div>
        <div className="event-body-item">
          <AiOutlineCalendar />
          <a>{getEventTimings(startTime!, endTime!)}</a>
        </div>
        <div className="event-body-item">
          <FiMapPin />
          <a>{event.location?.name}</a>
        </div>
        <div className="event-body-item">
          <MdPeopleOutline />
          <a>{`Number of allowed guests is ${event.max_people}`}</a>
        </div>
        <GuestList participants={event.participants} />
      </div>
      <div className="event-footer">
        <EventFooter
          eventId={eventId}
          userId={user.id}
          userStatus={event.user_details?.user_status}
          paymentStatus={event.user_details?.payment_status}
          event={event}
          participantCount={event.participants?.length}
          setEvent={setEvent}
          setShowPaymentModal={setShowPaymentModal}
          guestCount={guestCount}
        />
        {showPaymentModal && paymentId && (
          <Pay
            paymentId={paymentId}
            onComplete={() =>
              Post(
                "/event/detail",
                { event_id: eventId, user_id: user.id },
                (data: Record<string, any>) => {
                  setShowPaymentModal(false);
                  setEvent(data);
                },
                () => {}
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default EventDetail;
