import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "./context";
import { Post } from "./requests";
import { redirectAfterLogin } from "./utils";
import "./app.css";

const Register = (props: Record<string, any>) => {
  const [data, setData] = useState({ phone: props.phone });
  const [nonce, setNonce] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  return showOtp ? (
    <OTP data={data} nonce={nonce} setShowOtp={setShowOtp} />
  ) : (
    <User
      data={data}
      setNonce={setNonce}
      setData={setData}
      setPhone={props.setPhone}
      setShowOtp={setShowOtp}
      onToggle={props.onToggle}
      verifiedPhone={props.verifiedPhone}
    />
  );
};

const User = (props: Record<string, any>) => {
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const { data, setData } = props;
  const fieldNames = ["firstName", "lastName", "email", "phone"];

  let newData: Record<string, any>,
    newErrors: Record<string, any>,
    newValid: Record<string, any>;
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [valid, setValid] = useState<Record<string, any>>({});

  const handleUpdate = (event: any) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    let fieldValidationMessage = event.target.validationMessage;
    if (fieldValue) {
      if (fieldValidationMessage === "Fill out this field") {
        fieldValidationMessage = "";
      }
    }

    newData = { ...data };
    newData[fieldName] = fieldValue;
    newErrors = { ...errors };
    newValid = { ...valid };

    validateField(fieldName, fieldValue, fieldValidationMessage);

    setData(newData);
    setErrors(newErrors);
    setValid(newValid);
  };

  const validateField = (
    name: string,
    value: string,
    validationMessage: string
  ) => {
    if (validationMessage) {
      newErrors[name] = validationMessage;
      validateForm(name, false);
    } else {
      newErrors[name] = "";
      validateForm(name, true);
    }
  };

  const validateForm = (updatedFieldName: string, isFieldValid: boolean) => {
    var isValid = isFieldValid;
    Object.keys(valid).forEach((fieldName) => {
      if (fieldName !== updatedFieldName && fieldName !== "form") {
        isValid &&= valid[fieldName];
      }
    });

    newValid[updatedFieldName] = isFieldValid;
    newValid["form"] = isValid;
  };

  const validateAllFields = () => {
    newErrors = { ...errors };
    newValid = { ...valid };

    fieldNames.forEach((fieldName) => {
      const elements = document.getElementsByName(fieldName);
      console.assert(elements.length == 1);
      const element = elements[0] as HTMLInputElement;

      validateField(fieldName, element.value, element.validationMessage);
    });

    setErrors(newErrors);
    setValid(newValid);

    return newValid["form"];
  };

  const onGenerate = (e: any) => {
    e.preventDefault();
    if (!validateAllFields()) return;

    if (data.phone == props.verifiedPhone) {
      Post(
        "/user/new",
        {
          first_name: data.firstName,
          last_name: data.lastName,
          phone: "91".concat(data.phone),
          email: data.email,
        },
        (respData: Record<string, any>) => {
          setUser({
            id: respData.user_id,
            name: {
              first: data.firstName,
              last: data.lastName,
            },
            phone: "91".concat(data.phone),
            email: data.email,
            token: respData.token,
          });

          redirectAfterLogin(navigate);
        },
        () => {}
      );

      return;
    }

    Post(
      "/auth/generate",
      {
        phone: "91".concat(data.phone),
      },
      (data: Record<string, any>) => {
        props.setNonce(data.nonce);
        props.setShowOtp(true);
      },
      () => {}
    );
  };

  const navigateToNextInput = (e: any) => {
    e.preventDefault();

    const nextElement =
      e.target.parentElement.nextElementSibling ||
      e.target.parentElement.parentElement.nextElementSibling;
    nextElement.querySelector("input").focus();
  };

  return (
    <div className="auth-form-container register-form-container">
      <img
        src={require("../misfits.png")}
        height="100px"
        width="150px"
        alt="logo"
      ></img>
      <h2 style={{ paddingTop: "1rem", marginBottom: "0.5rem" }}>
        Create your account
      </h2>
      <h3 style={{ marginTop: "0", fontWeight: "5" }}>
        to continue to Misfits
      </h3>
      <form className="register-form">
        <div className="auth-name-input">
          <div className="auth-input-label">
            <input
              id="firstName"
              name="firstName"
              className="auth-input"
              value={data.firstName}
              type="text"
              placeholder=" "
              onChange={handleUpdate}
              onKeyDown={(e) => {
                if (e.key === "Enter") navigateToNextInput(e);
              }}
              required
            />
            <label htmlFor="firstName" className="auth-label">
              First name
            </label>
            {!valid["firstName"] && (
              <label className="auth-form-error">{errors["firstName"]}</label>
            )}
          </div>
          <div className="auth-input-label">
            <input
              id="lastName"
              name="lastName"
              className="auth-input"
              value={data.lastName}
              type="text"
              placeholder=" "
              onChange={handleUpdate}
              onKeyDown={(e) => {
                if (e.key === "Enter") navigateToNextInput(e);
              }}
              required
            />
            <label htmlFor="lastName" className="auth-label">
              Last name
            </label>
            {!valid["lastName"] && (
              <label className="auth-form-error">{errors["lastName"]}</label>
            )}
          </div>
        </div>
        <div className="auth-input-label">
          <input
            id="email"
            name="email"
            className="auth-input"
            value={data.email}
            type="email"
            placeholder=" "
            onChange={handleUpdate}
            onKeyDown={(e) => {
              if (e.key === "Enter") navigateToNextInput(e);
            }}
            required
          />
          <label htmlFor="email" className="auth-label">
            Email
          </label>
          {!valid["email"] && (
            <label className="auth-form-error">{errors["email"]}</label>
          )}
        </div>
        <div className="auth-input-label">
          <input
            id="phone"
            name="phone"
            className="auth-input"
            value={data.phone}
            type="tel"
            placeholder=" "
            onChange={(e) => {
              handleUpdate(e);
              props.setPhone(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") onGenerate(e);
            }}
            required
          />
          <label htmlFor="phone" className="auth-label">
            Phone number
          </label>
          {!valid["phone"] && (
            <label className="auth-form-error">{errors["phone"]}</label>
          )}
        </div>
      </form>
      <div className="auth-btns-container">
        <button className="no-btn" onClick={() => props.onToggle("login")}>
          Sign in instead
        </button>
        <button
          className="yes-btn"
          onClick={onGenerate}
          disabled={!valid["form"]}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const OTP = (props: Record<string, any>) => {
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { data: userData } = props;

  const onValidate = (e: any) => {
    e.preventDefault();

    Post(
      "/auth/validate",
      {
        otp: otp,
        nonce: props.nonce,
      },
      (data: Record<string, any>) => {
        if (Object.keys(data).length === 0) {
          Post(
            "/user/new",
            {
              first_name: userData.firstName,
              last_name: userData.lastName,
              phone: "91".concat(userData.phone),
              email: userData.email,
            },
            (data: Record<string, any>) => {
              setUser({
                id: data.user_id,
                name: {
                  first: userData.firstName,
                  last: userData.lastName,
                },
                phone: "91".concat(userData.phone),
                email: userData.email,
                token: data.token,
              });

              redirectAfterLogin(navigate);
            },
            (err: string) => {
              setOtpError(err);
            }
          );
        } else {
          setOtpError("You are already registered, logging you in.");
          setUser({
            ...data.user,
            token: data.token,
          });

          setTimeout(() => redirectAfterLogin(navigate), 500);
        }
      },
      (err: string) => {
        setOtpError(err);
      }
    );
  };

  return (
    <div className="auth-form-container">
      <img
        src={require("../misfits.png")}
        height="100px"
        width="150px"
        alt="logo"
      ></img>
      <h2 style={{ paddingTop: "1rem", marginBottom: "0.5rem" }}>Enter OTP</h2>
      <h3 style={{ marginTop: "0", fontWeight: "5" }}>
        to continue to Misfits
      </h3>
      <form className="login-form" onSubmit={onValidate}>
        <div className="auth-input-label">
          <input
            id="otp"
            name="otp"
            className="auth-input"
            value={otp}
            type="text"
            inputMode="numeric"
            placeholder=" "
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOtp(e.target.value)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") onValidate(e);
            }}
            required
          />
          <label htmlFor="otp" className="auth-label">
            OTP
          </label>
          {otpError && <label className="auth-form-error">{otpError}</label>}
        </div>
      </form>
      <div className="auth-btns-container">
        <button className="no-btn" onClick={() => props.setShowOtp(false)}>
          Back to register
        </button>
        <button className="yes-btn" onClick={onValidate}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Register;
