function redirectAfterLogin(navigate: Function) {
  const redirect = window.localStorage.getItem("redirect");
  if (redirect) {
    navigate(redirect);
  } else {
    navigate("/event");
  }
}

function detectDeviceType() {
  const userAgent = navigator.userAgent;

  if (/Android/i.test(userAgent)) {
      return 'Android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return 'iOS';
  } else if (/Windows|Mac|Linux/i.test(userAgent)) {
      return 'Laptop/Desktop';
  } else {
      return 'Unknown';
  }
}

export { redirectAfterLogin, detectDeviceType };
