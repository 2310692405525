const Url = "https://misfits.net.in/api";
// const Url = "http://localhost:8000/api";

function Post(url: string, reqData: Record<string, any>, fn: Function, errFn: Function) {
  fetch(Url + url, {
    method: "POST",
    body: JSON.stringify(reqData),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => {
      if (response.ok) {
        return { ok: true, data: response.json() };
      } else {
        return { ok: false, data: response.text() };
      }
    })
    .then(async (respData) => {
      const data = await respData.data;
      const ok = respData.ok;
      if (ok) fn(data);
      else errFn(data);
    })
    .catch((err) => {
      console.log(err);
    });
}

export { Post };
