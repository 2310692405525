import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "./context";
import { Post } from "./requests";
import { redirectAfterLogin } from "./utils";
import "./app.css";

const Login = (props: Record<string, any>) => {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const [nonce, setNonce] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  useEffect(() => {
    if (user.token && user.name !== undefined) {
      navigate("/event");
    }
  });

  return showOtp ? (
    <OTP
      phone={props.phone}
      nonce={nonce}
      setShowOtp={setShowOtp}
      setVerifiedPhone={props.setVerifiedPhone}
      onToggle={props.onToggle}
    />
  ) : (
    <Phone
      phone={props.phone}
      setPhone={props.setPhone}
      setShowOtp={setShowOtp}
      setNonce={setNonce}
      onToggle={props.onToggle}
    />
  );
};

const Phone = (props: Record<string, any>) => {
  const [phoneError, setPhoneError] = useState("");

  const onGenerate = (e: any) => {
    e.preventDefault();
    if (props.phone === "") return;

    Post(
      "/auth/generate",
      {
        phone: "91".concat(props.phone),
      },
      (data: Record<string, any>) => {
        props.setNonce(data.nonce);
        props.setShowOtp(true);
      },
      (err: string) => {
        setPhoneError(err);
      }
    );
  };

  return (
    <>
      <div className="auth-form-container">
        <img
          src={require("../misfits.png")}
          height="100px"
          width="150px"
          alt="logo"
        ></img>
        <h2 style={{ paddingTop: "1rem", marginBottom: "0.5rem" }}>Sign in</h2>
        <h3 style={{ marginTop: "0", fontWeight: "5" }}>
          to continue to Misfits
        </h3>
        <form className="login-form">
          <div className="auth-input-label">
            <input
              id="phone"
              name="phone"
              className="auth-input"
              value={props.phone}
              type="tel"
              placeholder=" "
              onChange={(e) => props.setPhone(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") onGenerate(e);
              }}
              required
            />
            <label htmlFor="phone" className="auth-label">
              Phone number
            </label>
            {phoneError && (
              <label className="auth-form-error">{phoneError}</label>
            )}
          </div>
        </form>
        <div className="auth-btns-container">
          <button className="no-btn" onClick={() => props.onToggle("register")}>
            Create account
          </button>
          <button className="yes-btn" onClick={onGenerate}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

const OTP = (props: Record<string, any>) => {
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const onValidate = (e: any) => {
    e.preventDefault();
    Post(
      "/auth/validate",
      {
        otp: otp,
        nonce: props.nonce,
      },
      (data: Record<string, any>) => {
        if (Object.keys(data).length !== 0) {
          setUser({
            ...data.user,
            token: data.token,
          });

          redirectAfterLogin(navigate);
        } else {
          props.setVerifiedPhone(props.phone);
          props.onToggle("register");
        }
      },
      (err: string) => {
        setOtpError(err);
      }
    );
  };

  return (
    <div className="auth-form-container">
      <img
        src={require("../misfits.png")}
        height="100px"
        width="150px"
        alt="logo"
      />
      <h2 style={{ paddingTop: "1rem", marginBottom: "0.5rem" }}>Enter OTP</h2>
      <h3 style={{ marginTop: "0", fontWeight: "5" }}>
        to continue to Misfits
      </h3>
      <form className="login-form" onSubmit={onValidate}>
        <div className="auth-input-label">
          <input
            id="otp"
            name="otp"
            className="auth-input"
            value={otp}
            type="text"
            inputMode="numeric"
            placeholder=" "
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOtp(e.target.value)
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") onValidate(e);
            }}
            required
          />
          <label htmlFor="otp" className="auth-label">
            OTP
          </label>
          {otpError && <label className="auth-form-error">{otpError}</label>}
        </div>
      </form>
      <div className="auth-btns-container">
        <button className="no-btn" onClick={() => props.setShowOtp(false)}>
          Back to login
        </button>
        <button className="yes-btn" onClick={onValidate}>
          Next
        </button>
      </div>
    </div>
  );
};
export default Login;
