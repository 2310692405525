import "./event.css";

const EventList = () => {
  return (
    <div className="event event-list">
      {<div className="zero-events">
        <h1>Welcome to Misfits!</h1>
      </div>
      }
    </div>
  );
};

export default EventList;
