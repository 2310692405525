import { useState } from "react";
import Login from "./login";
import Register from "./register";
import "./app.css";

const App = () => {
  const [phone, setPhone] = useState("");
  const [verifiedPhone, setVerifiedPhone] = useState("");
  const [formType, setFormType] = useState("login");
  const onToggle = (formType: string) => setFormType(formType);

  return (
    <div className="app">
      {formType === "login" ? (
        <Login phone={phone} setPhone={setPhone} setVerifiedPhone={setVerifiedPhone} onToggle={onToggle} />
      ) : (
        <Register phone={phone} setPhone={setPhone} verifiedPhone={verifiedPhone} onToggle={onToggle} />
      )}
      <div className="terms-of-service-footer">
        <div className="terms-of-service">
          <button onClick={() => window.open("/pdf?filename=terms_and_conditions.pdf")}>Terms and conditions</button>
          &nbsp;&nbsp;
          <button onClick={() => window.open("/pdf?filename=privacy_policy.pdf")}>Privacy policy</button>
          <br />
          <button onClick={() => window.open("/pdf?filename=refund_policy.pdf")}>Cancellations and refunds</button>
          &nbsp;&nbsp;
          <button onClick={() => window.open("/pdf?filename=contact_us.pdf")}>Contact us</button>
          <br />
          <label>O28 Community India Pvt Ltd</label>
        </div>
      </div>

    </div>
  );
};

export default App;
