import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import useRazorpay from "react-razorpay";

import AppContext from "./context";
import { Post } from "./requests";
import "./pay.css";
import { detectDeviceType } from "./utils";

const Pay = (props: Record<string, any>) => {
  const { user } = useContext(AppContext);
  const [Razorpay] = useRazorpay();
  const [payment, setPayment] = useState<Record<string, any>>({});
  const paymentInitialised = useRef<Boolean>(false);

  useEffect(() => {
    if (!paymentInitialised.current) {
      if (payment.PaymentDetails) {
        const { PaymentDetails: paymentDetails } = payment;
        if (
          paymentDetails.SetuDetails &&
          Object.keys(paymentDetails.SetuDetails).length
        ) {
          launchSetu();
          paymentInitialised.current = true;
        }

        if (
          paymentDetails.RazorpayDetails &&
          Object.keys(paymentDetails.RazorpayDetails).length
        ) {
          launchRazorpay();
          paymentInitialised.current = true;
        }
      }
    }

    if (payment.state) {
      return;
    }

    Post(
      "/pay",
      { payment_id: props.paymentId },
      (data: Record<string, any>) => {
        setTimeout(
          () => setPayment(data),
          paymentInitialised.current ? 5000 : 500
        );
      },
      () => {}
    );
  });

  const launchSetu = () => {
    const deviceType = detectDeviceType();

    let link = document.createElement("a");
    document.body.appendChild(link);

    if (deviceType == "Android") {
      link.href = payment.PaymentDetails.SetuDetails.upi_deep_link;
    } else {
      link.href = payment.PaymentDetails.SetuDetails.short_url;
    }
    link.click();
    document.body.removeChild(link);
  };

  const launchRazorpay = () => {
    var options = {
      key: "rzp_live_L2um20zK84BOE3",
      amount: payment.amount,
      currency: "INR",
      name: "Misfits",
      order_id: payment.PaymentDetails.RazorpayDetails.order_id,
      prefill: {
        contact: user.phone,
      },
    };

    var rzp = new Razorpay(options);
    rzp.open();
  };

  const getTitle = () => {
    switch (payment.state) {
      case 1:
        return <h2>Payment successful</h2>;
      case 2:
        return <h2>Payment failed</h2>;
      default:
        return <h2>Payment in progress</h2>;
    }
  };

  const getSubTitle = () => {
    switch (payment.state) {
      case 1:
        return <br />;
      case 2:
        return <h3>Any amount deducted will be refunded in 3-5 days</h3>;
      default:
        return <h3>Please do not refresh or go back!</h3>;
    }
  };

  const getIcon = () => {
    switch (payment.state) {
      case 1:
        return (
          <AiOutlineCheckCircle
            style={{ color: "lightgreen", fontSize: "48px" }}
          />
        );
      case 2:
        return (
          <AiOutlineCloseCircle style={{ color: "red", fontSize: "48px" }} />
        );
      default:
        return <div className="spinner"></div>;
    }
  };

  const getCloseButton = () => {
    if (payment.state) {
      return <button onClick={props.onComplete}>Click here to go back</button>;
    } else {
      return null;
    }
  };

  return (
    <div className="pay">
      {getTitle()}
      {getSubTitle()}
      {getIcon()}
      {getCloseButton()}
    </div>
  );
};

export default Pay;
