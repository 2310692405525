const getDatetimeObj = (datetime: Record<string, any>) => {
  if (!datetime) {
    return null;
  }

  return new Date(
    datetime.date.year,
    datetime.date.month - 1, // need to subtract 1 since JS months are 0-indexed
    datetime.date.day,
    datetime.time.hour || 0,
    datetime.time.minute || 0,
    0,
    0
  );
};

const getEventTimings = (startTime: Date, endTime: Date) => {
  const weekday = startTime?.toLocaleString("default", { weekday: "short" });
  const month = startTime?.toLocaleString("default", { month: "short" });
  const startTimeStr = startTime?.toLocaleString("default", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTimeStr = endTime?.toLocaleString("default", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${weekday}, ${startTime?.getDate()} ${month}, ${startTimeStr} - ${endTimeStr}`;
};

export {
  getDatetimeObj,
  getEventTimings,
};
